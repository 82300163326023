<template>
  <div class="ms-self-inspection-mian">
    <el-form class="zwx-form" key="msForm" :model="msForm" ref="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="项目名称：" prop="itemName" label-width="80px">
          <el-input class="zwx-input" placeholder="请输入" v-model.trim="msForm.itemName" clearable></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="是否启用：" prop="ifEnable">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableList">
            <el-checkbox class="zwx-checkbox" :label="true">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="false">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" style="margin-left: 15px" @click="quertResult(1)">查询</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" style="margin-left: 20px !important" @click="add">添加</el-button>
      </div>
    </el-form>
    <!-- table显示部分  -->
    <div style="height:calc(100vh - 171px);">
      <el-table class="zwx-table" style="width: 100%" row-key="rid" key="msTable" :data="msTable" stripe ref="msTable" border  height="calc(100vh - 169px)">
        <template slot="empty">
          <div class="no-data"></div>
          <div>暂时还没有数据哦~</div>
        </template>
        <el-table-column label="编码" min-width="80" header-align="center" align="left" prop="codeNo"></el-table-column>
        <el-table-column label="层级编码" min-width="100" prop="levelCodeNo" header-align="center" align="left"></el-table-column>
        <el-table-column prop="itemName" label="项目名称" min-width="140" header-align="center" align="left">
             <template slot-scope="scope">
            <span v-if="getCount('.',scope.row.levelCodeNo) == 0" >{{ scope.row.itemName }}</span>
            <span v-if="getCount('.',scope.row.levelCodeNo) == 1" style="margin-left:8px">{{ scope.row.itemName }}</span>
            <span v-if="getCount('.',scope.row.levelCodeNo) == 2" style="margin-left:16px">{{ scope.row.itemName }}</span>
            <span v-if="getCount('.',scope.row.levelCodeNo) == 3" style="margin-left:24px">{{ scope.row.itemName }}</span>
            <span v-if="getCount('.',scope.row.levelCodeNo) == 4" style="margin-left:32px">{{ scope.row.itemName }}</span>
            <span v-if="getCount('.',scope.row.levelCodeNo) == 5" style="margin-left:40px">{{ scope.row.itemName }}</span>
        </template>
        </el-table-column>
        <el-table-column prop="titleItem" label="是否标题项" width="120" header-align="center" align="center">
            <template slot-scope='scope'>
                {{scope.row.titleItem==null?'--':scope.row.titleItem?'是':'否'}}
            </template>
        </el-table-column>
        <el-table-column prop="keyItem" label="是否关键项" width="120" header-align="center" align="center">
            <template slot-scope='scope'>
                {{scope.row.keyItem==null?'--':scope.row.keyItem?'是':'否'}}
            </template>
        </el-table-column>
        <el-table-column prop="dependentSubproject" label="是否依赖子项目" width="130" header-align="center" align="center">
            <template slot-scope='scope'>
                {{scope.row.dependentSubproject==null?'--':scope.row.dependentSubproject?'是':'否'}}
            </template>
        </el-table-column>
        <el-table-column prop='ifEnable' label='状态' width='80' header-align='center' align='center'>
        <template slot-scope='scope'>
          <span v-html='dutyFormatter(scope.row)'></span>
        </template>
      </el-table-column>
        <el-table-column  label="操作" min-width="110" header-align="left" align="left">
                            <template slot-scope="scope">
                            <el-button class="zwx-button zwx-button-text-26" type="text" @click="editPlatformMenu(scope.row)">编辑</el-button>
                            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="deleteSelfInspectionItemByID(scope.row.rid)">删除</el-button>
                            <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === false" @click="enableOrDisableSelfInspectionItemByID(scope.row.rid,true)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === true" @click="disableSelfInspectionItemByID(scope.row.rid,false)" style="color:rgb(231, 43, 0) !important;">停用</el-button>
                            </template>
                        </el-table-column>
      </el-table>
    </div>
    <fixed-footer>
      <div style="display: flex">
        <base-pagination  :parentPage="msPag.currentPage" :pageSize="msPag.pageSize" :total="msPag.total" @currentChange="quertResult" />
      </div>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'SelfInspectionItem',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        itemName: null,
        ifEnableList: [true],
      },
      msTable:[],
      msPag:{
        currentPage:1,
        pageSize:18,
        total:0
      }
    }
  },
  mounted() {},
  watch: {},
    activated() {
    if (this.$route.params.fresh) {
      this.quertResult(1)
    }
  },
  created() {
    this.quertResult(1)
  },
  methods: {
    quertResult(currentPage) {
        var ifEnable =null
        if(this.msForm.ifEnableList.length==0 || this.msForm.ifEnableList.length==2){
            ifEnable =null
        }
        else{
            ifEnable =this.msForm.ifEnableList[0]
        }
        this.msPag.currentPage =currentPage
      let data = {
        itemName: this.msForm.itemName,
        currentPage:currentPage,
        ifEnable: ifEnable,
      }
      this.$emit('loading', true)
      this.$system.get(this.api + '/oh/classification/getSelfInspectionItemList-1', data, true, true, (data) => {
        if (data.type === '00') {
          this.msTable = data.selfInspectionItemList
          this.msPag.pageSize =data.pageSize
          this.msPag.total =data.selfInspectionItemCount
          this.$emit('loading', false)
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$notify.error({ title: '错误', message: data.mess })
        }
      })
    },
    dutyFormatter(row) {
      var ifEnable = row.ifEnable
      if (ifEnable != null && ifEnable != undefined) {
        if (ifEnable == true) return '<span style="color: #15B150">启用</span>'
        if (ifEnable == false) return '<span style="color: #E53400">停用</span>'
      } else {
        return ''
      }
    },
    /**
     * 获取menuNo层级
     */
    getCount(SubChar, StrTemp) {
      let count, i
      count = 0
      for (i = 0; i < StrTemp.length; i++) {
        if (StrTemp.charAt(i, 1) == SubChar) { 
          count = count + 1
        }
      }
      return count
    },
    /**
     * 启用/停用
     */
    enableOrDisableSelfInspectionItemByID(rid,ifEnable) {
      let data = {
        rid:rid,
        ifEnable:ifEnable
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/classification/enableOrDisableSelfInspectionItemByID-1',
        data,
        true,
        true,
        (data) => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$emit('loading', false)
            this.quertResult(this.msPag.currentPage)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        (data) => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    disableSelfInspectionItemByID(rid,ifEnable){
         this.$system.msgbox('', '提示', '是否确定停用？', '确定', '取消', () => {
            this.enableOrDisableSelfInspectionItemByID(rid,ifEnable)
         })
    },

   /**
   * 删除
   */
  deleteSelfInspectionItemByID(rid) {
     this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
      let data = {
        rid:rid
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/classification/deleteSelfInspectionItemByID-1',
        data,
        true,
        true,
        (data) => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$emit('loading', false)
            this.quertResult(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        (data) => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
  })
},
/**
 * 添加路由跳转
 */
    add(){
        this.$router.push({ name: 'SelfInspectionItemEdit', params: { type: 'add' } }) 
    },
    //编辑
    editPlatformMenu(row) {
      this.$router.push({ name: 'SelfInspectionItemEdit', params: { rid: row.rid, type: 'edit' } })
    },
  },
}
</script>
<style lang="less" scoped>
    .no-data {
    display: inline-block;
    vertical-align: sub;
    width: 242px;
    height: 133px;
    background: url('~@/assets/images/login/image_meiyoushuju.svg') no-repeat center;
    background-size: contain;
    }
</style>
<style>
</style>

