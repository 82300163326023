var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quartz-manage" },
    [
      _c(
        "el-form",
        {
          key: "msUploadRecordSearchForm",
          ref: "msUploadRecordSearchForm",
          staticClass: "zwx-form",
          attrs: {
            model: _vm.msUploadRecordSearchForm,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "行政区划：" } },
                [
                  _c("zwx-select-area", {
                    ref: "areaIdArea",
                    attrs: {
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: false,
                      disabled: false,
                      clearable: false
                    },
                    on: {
                      loadOver: function($event) {
                        return _vm.areaIdLoadOver()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "业务名称：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "50",
                      clearable: ""
                    },
                    model: {
                      value: _vm.msUploadRecordSearchForm.bussinessNameLike,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msUploadRecordSearchForm,
                          "bussinessNameLike",
                          $$v
                        )
                      },
                      expression: "msUploadRecordSearchForm.bussinessNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "上报时间：" } },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "135px" },
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "picker-options": _vm.$validate.noBigDateNoOverToday(
                        _vm.$data["msUploadRecordSearchForm"],
                        "uploadTimeBegin"
                      ),
                      placeholder: "开始日期"
                    },
                    model: {
                      value: _vm.msUploadRecordSearchForm.uploadTimeBegin,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msUploadRecordSearchForm,
                          "uploadTimeBegin",
                          $$v
                        )
                      },
                      expression: "msUploadRecordSearchForm.uploadTimeBegin"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "6px",
                        "margin-right": "6px"
                      }
                    },
                    [_vm._v("~")]
                  ),
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "135px" },
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      "picker-options": _vm.$validate.noSmallDateNoOverToday(
                        _vm.$data["msUploadRecordSearchForm"],
                        "uploadTimeEnd"
                      ),
                      placeholder: "结束日期"
                    },
                    model: {
                      value: _vm.msUploadRecordSearchForm.uploadTimeEnd,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msUploadRecordSearchForm,
                          "uploadTimeEnd",
                          $$v
                        )
                      },
                      expression: "msUploadRecordSearchForm.uploadTimeEnd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "业务类型：" } },
                [
                  _c("code-multiple", {
                    attrs: { codeType: "1017", collapseTags: true },
                    model: {
                      value: _vm.msUploadRecordSearchForm.bussinessTypes,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msUploadRecordSearchForm,
                          "bussinessTypes",
                          $$v
                        )
                      },
                      expression: "msUploadRecordSearchForm.bussinessTypes"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "上报状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msUploadRecordSearchForm.uploadStates,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msUploadRecordSearchForm,
                            "uploadStates",
                            $$v
                          )
                        },
                        expression: "msUploadRecordSearchForm.uploadStates"
                      }
                    },
                    _vm._l(_vm.uploadStateArrList, function(itm, index) {
                      return _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          style: index != 0 ? "margin-left: 10px;" : "",
                          attrs: { label: itm.code }
                        },
                        [_vm._v(_vm._s(itm.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "80px", label: "待办状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msUploadRecordSearchForm.dealStates,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.msUploadRecordSearchForm,
                            "dealStates",
                            $$v
                          )
                        },
                        expression: "msUploadRecordSearchForm.dealStates"
                      }
                    },
                    _vm._l(_vm.dealStateArrList, function(itm, index) {
                      return _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          style: index != 0 ? "margin-left: 10px;" : "",
                          attrs: { label: itm.code }
                        },
                        [_vm._v(_vm._s(itm.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("el-divider", { staticClass: "base-divider" }),
      _c(
        "div",
        { staticStyle: { padding: "12px 20px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.search(1)
                }
              }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.tableList,
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "zoneName",
              label: "行政区划",
              width: "320",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bussinessName",
              label: "业务名称",
              width: "320",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "businessTypeName",
              label: "业务类型",
              width: "200",
              "header-align": "center",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "uploadTime",
              label: "上报时间",
              width: "230",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function({ row }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$system.formatDate(
                            row.uploadTime,
                            "yyyy-MM-DD HH:mm:ss"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "uploadState",
              label: "上报状态",
              width: "130",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.uploadState == 0
                      ? _c("span", [_vm._v("待上报")])
                      : _vm._e(),
                    scope.row.uploadState == 1
                      ? _c("span", { staticStyle: { color: "#0a6ebd" } }, [
                          _vm._v("上报中")
                        ])
                      : _vm._e(),
                    scope.row.uploadState == 2
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("上报成功")
                        ])
                      : _vm._e(),
                    scope.row.uploadState == 3
                      ? _c("span", { staticStyle: { color: "#ff0309" } }, [
                          _vm._v("上报失败")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dealState",
              label: "待办状态",
              width: "130",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.dealState == 0
                      ? _c("span", [_vm._v("未处理")])
                      : _vm._e(),
                    scope.row.dealState == 1
                      ? _c("span", [_vm._v("已处理")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": _vm.$system.calMinWidth({
                wordNum: 4,
                arrowNum: 1,
                btnNum: 3
              }),
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        staticStyle: { "margin-left": "0" },
                        on: {
                          click: function($event) {
                            return _vm.lookUploadData(scope.row.dataContent)
                          }
                        }
                      },
                      [_vm._v("数据查看")]
                    ),
                    scope.row.uploadState == 3
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              click: function($event) {
                                return _vm.lookFailReason(scope.row.failReason)
                              }
                            }
                          },
                          [_vm._v("失败原因")]
                        )
                      : _vm._e(),
                    _c("br"),
                    scope.row.uploadState == 3 && scope.row.dealState == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            on: {
                              click: function($event) {
                                return _vm.rereportUploadData(scope.row.rid)
                              }
                            }
                          },
                          [_vm._v("重新上报")]
                        )
                      : _vm._e(),
                    scope.row.uploadState == 3 && scope.row.dealState == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              click: function($event) {
                                return _vm.noReportUploadDataState(
                                  scope.row.rid
                                )
                              }
                            }
                          },
                          [_vm._v("不再上报")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        attrs: {
          parentPage: _vm.currentPage,
          pageSize: _vm.pageSize,
          total: _vm.total
        },
        on: { currentChange: _vm.currentChange }
      }),
      _c(
        "el-dialog",
        {
          staticClass: "zwx-dialog",
          attrs: {
            title: "上报数据",
            visible: _vm.uploadDataDialogVisible,
            width: "800px",
            modal: true
          },
          on: {
            "update:visible": function($event) {
              _vm.uploadDataDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "left",
                height: "400px",
                "overflow-y": "auto"
              }
            },
            [_vm._v(" " + _vm._s(_vm.uploadDataDialogForm) + " ")]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "zwx-dialog",
          attrs: {
            title: "失败原因",
            visible: _vm.failReasonVisible,
            width: "800px",
            modal: true
          },
          on: {
            "update:visible": function($event) {
              _vm.failReasonVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "left",
                height: "400px",
                "overflow-y": "auto"
              }
            },
            _vm._l(_vm.failReasonList, function(itm, index) {
              return _c("div", [_vm._v(_vm._s(index + 1) + "、" + _vm._s(itm))])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }