<template>
  <div class="quartz-manage">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msUploadRecordSearchForm" ref="msUploadRecordSearchForm" :model="msUploadRecordSearchForm" label-position="right" @submit.native.prevent>
      <div>
        <el-form-item label-width="80px" label="行政区划：">
          <zwx-select-area ref="areaIdArea" @loadOver="areaIdLoadOver()" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :disabled="false" :clearable="false" />
        </el-form-item>
        <el-form-item label-width="80px" label="业务名称：">
          <el-input class="zwx-input" v-model="msUploadRecordSearchForm.bussinessNameLike" placeholder="请输入" maxlength="50" clearable></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="上报时间：">
          <el-date-picker class="zwx-date-picker" style="width:135px;" v-model="msUploadRecordSearchForm.uploadTimeBegin" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :picker-options="$validate.noBigDateNoOverToday($data['msUploadRecordSearchForm'], 'uploadTimeBegin')" placeholder="开始日期"></el-date-picker>
          <span style="margin-left:6px;margin-right:6px;">~</span>
          <el-date-picker class="zwx-date-picker" style="width:135px;" v-model="msUploadRecordSearchForm.uploadTimeEnd" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" :picker-options="$validate.noSmallDateNoOverToday($data['msUploadRecordSearchForm'], 'uploadTimeEnd')" placeholder="结束日期"></el-date-picker>
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="80px" label="业务类型：">
          <code-multiple v-model="msUploadRecordSearchForm.bussinessTypes" codeType="1017" :collapseTags="true"></code-multiple>
        </el-form-item>
        <el-form-item label-width="80px" label="上报状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msUploadRecordSearchForm.uploadStates">
            <el-checkbox class="zwx-checkbox" v-for="(itm, index) in uploadStateArrList" :label="itm.code" :style="index != 0 ? 'margin-left: 10px;' : ''">{{ itm.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label-width="80px" label="待办状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msUploadRecordSearchForm.dealStates">
            <el-checkbox class="zwx-checkbox" v-for="(itm, index) in dealStateArrList" :label="itm.code" :style="index != 0 ? 'margin-left: 10px;' : ''">{{ itm.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 20px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="tableList" border stripe>
      <el-table-column prop="zoneName" label="行政区划" width="320" header-align="center" align="left"></el-table-column>
      <el-table-column prop="bussinessName" label="业务名称" width="320" header-align="center" align="left"></el-table-column>
      <el-table-column prop="businessTypeName" label="业务类型" width="200" header-align="center" align="center"></el-table-column>
      <el-table-column prop="uploadTime" label="上报时间" width="230" header-align="center" align="center">
        <template slot-scope="{ row }">
          {{ $system.formatDate(row.uploadTime, 'yyyy-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="uploadState" label="上报状态" width="130" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.uploadState == 0">待上报</span>
          <span v-if="scope.row.uploadState == 1" style="color: #0a6ebd">上报中</span>
          <span v-if="scope.row.uploadState == 2" style="color: green">上报成功</span>
          <span v-if="scope.row.uploadState == 3" style="color: #ff0309">上报失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealState" label="待办状态" width="130" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.dealState == 0">未处理</span>
          <span v-if="scope.row.dealState == 1">已处理</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 4, arrowNum: 1, btnNum: 3 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" style="margin-left: 0;" @click="lookUploadData(scope.row.dataContent)">数据查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" style="margin-left: 10px;" v-if="scope.row.uploadState == 3" @click="lookFailReason(scope.row.failReason)">失败原因</el-button>
          <br />
          <el-button class="zwx-button zwx-button-text-26" v-if="scope.row.uploadState == 3 && scope.row.dealState == 0" @click="rereportUploadData(scope.row.rid)">重新上报</el-button>
          <el-button class="zwx-button zwx-button-text-26" style="margin-left: 10px;" v-if="scope.row.uploadState == 3 && scope.row.dealState == 0" @click="noReportUploadDataState(scope.row.rid)">不再上报</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
    <el-dialog class="zwx-dialog" title="上报数据" :visible.sync="uploadDataDialogVisible" width="800px" :modal="true">
      <div style="text-align: left;height: 400px;overflow-y: auto">
        {{ uploadDataDialogForm }}
      </div>
    </el-dialog>
    <el-dialog class="zwx-dialog" title="失败原因" :visible.sync="failReasonVisible" width="800px" :modal="true">
      <div style="text-align: left;height: 400px;overflow-y: auto">
        <div v-for="(itm, index) in failReasonList">{{ index + 1 }}、{{ itm }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'uploadRecordList',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      zoneCode12From: undefined,
      msUploadRecordSearchForm: {
        searchBussinessZone: null,
        searchZoneType: null,
        bussinessNameLike: null,
        uploadTimeBegin: null,
        uploadTimeEnd: null,
        bussinessTypes: null,
        uploadStates: [],
        dealStates: [],
        currentPage: 1,
      },
      currentPage: 1,
      pageSize: null,
      total: null,
      tableList: [],
      uploadStateArrList: [
        {
          code: '0',
          name: '待上报',
        },
        {
          code: '1',
          name: '上报中',
        },
        {
          code: '2',
          name: '上报成功',
        },
        {
          code: '3',
          name: '上报失败',
        },
      ],
      dealStateArrList: [
        {
          code: '0',
          name: '待处理',
        },
        {
          code: '1',
          name: '已处理',
        },
      ],
      uploadDataDialogVisible: false,
      uploadDataDialogForm: null,
      failReasonVisible: false,
      failReasonList: [],
    }
  },
  created() {
    this.zoneCode12From = this.users.ifSuperAdmin ? '000000000000' : this.users.fkByUnitId.fkByZoneId.zoneCode12
  },
  mounted() {
    this.$refs.areaIdArea.initSearch()
  },
  activated() {
    if (this.$route.params.fresh) this.search(this.currentPage)
  },
  computed: {},
  methods: {
    currentChange(currentPage) {
      this.search(currentPage)
    },
    areaIdLoadOver() {
      this.search(this.currentPage)
    },
    search(currentPage) {
      this.$emit('loading', true)
      this.msUploadRecordSearchForm.currentPage = currentPage
      this.msUploadRecordSearchForm.searchBussinessZone = this.$refs.areaIdArea.getChooseArea().zoneCode12
      this.msUploadRecordSearchForm.searchBussinessZoneType = this.$refs.areaIdArea.getChooseArea().zoneType
      this.$system.postJson(this.api + '/ohss/data/exchange/getDataUploadRecordList-1', this.msUploadRecordSearchForm, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.tableList = data.dataUploadRecordList
        this.total = data.dataUploadRecordCount
        this.pageSize = data.pageSize
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    lookUploadData: function (val) {
      var that = this
      that.uploadDataDialogForm = val
      that.uploadDataDialogVisible = true
    },
    lookFailReason: function (val) {
      if (this.$zwxBase.verifyIsNotBlank(val)) {
        this.failReasonList = val.split('$@$')
        this.failReasonVisible = true
      }
    },
    rereportUploadData: function (rid) {
      this.$system.msgbox(
        '',
        '提示',
        '是否确定重新上报？',
        '确定',
        '取消',
        () => {
          this.$emit('loading', true)
          const data = {
            rid: rid,
          }
          this.$system.postJson(
            this.api + '/ohss/data/exchange/rereportUploadData-1',
            data,
            true,
            true,
            body => {
              this.$emit('loading', false)
              if (body.type === '00') {
                this.$system.notify('成功', body.mess, 'success')
                this.$nextTick(() => {
                  this.search(1)
                })
              } else {
                this.$system.notify('提示', body.mess, 'info')
              }
            },
            data => {
              this.$emit('loading', false)
            }
          )
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    noReportUploadDataState: function (rid) {
      this.$system.msgbox('', '提示', '是否确定不再上报？', '确定', '取消', () => {
        this.$emit('loading', true)
        const data = {
          rid: rid,
        }
        this.$system.postJson(
          this.api + '/ohss/data/exchange/noReportUploadDataState-1',
          data,
          true,
          true,
          body => {
            this.$emit('loading', false)
            if (body.type === '00') {
              this.$system.notify('成功', body.mess, 'success')
              this.$nextTick(() => {
                this.search(1)
              })
            } else {
              this.$system.notify('提示', body.mess, 'info')
            }
          },
          data => {
            this.$emit('loading', false)
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
